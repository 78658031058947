import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-462c92c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "search" }
const _hoisted_3 = { class: "filters" }
const _hoisted_4 = { class: "results" }
const _hoisted_5 = {
  key: 0,
  class: "empty-state"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "order-header" }
const _hoisted_9 = { class: "order-id" }
const _hoisted_10 = { class: "order-tags" }
const _hoisted_11 = { class: "order-metadata" }
const _hoisted_12 = { class: "order-items" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { slot: "end" }
const _hoisted_16 = { slot: "end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Orders")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "secondary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deselectAlert()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.selectedItemsCount) + " " + _toDisplayString(_ctx.$t("item selected")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, { onClick: _ctx.openActiveJobs }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        color: _ctx.isJobPending ? "warning" : "",
                        icon: _ctx.hourglass,
                        slot: "icon-only"
                      }, null, 8, ["color", "icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        ref: "contentRef",
        "scroll-events": true,
        onIonScroll: _cache[21] || (_cache[21] = ($event: any) => (_ctx.enableScrolling()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_searchbar, {
                onIonFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectSearchBarText($event))),
                onIonClear: _cache[2] || (_cache[2] = ($event: any) => {_ctx.query.queryString = ''; _ctx.updateQuery()}),
                value: _ctx.query.queryString,
                onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => {_ctx.query.queryString = $event.target.value; _ctx.updateQuery()}, ["enter"]))
              }, null, 8, ["value"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Ordered after")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_chip, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "aria-label": "ordered-after",
                        modelValue: _ctx.query.orderedAfter,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.query.orderedAfter) = $event)),
                        onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateQuery())),
                        type: "date"
                      }, null, 8, ["modelValue"]),
                      (_ctx.query.orderedAfter)
                        ? (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 0,
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.query.orderedAfter = "")),
                            icon: _ctx.close
                          }, null, 8, ["icon"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Ordered before")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_chip, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "aria-label": "ordered-before",
                        modelValue: _ctx.query.orderedBefore,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.query.orderedBefore) = $event)),
                        onIonChange: _cache[8] || (_cache[8] = ($event: any) => {_ctx.query.orderedBefore = $event.target.value; _ctx.updateQuery()}),
                        type: "date"
                      }, null, 8, ["modelValue"]),
                      (_ctx.query.orderedBefore)
                        ? (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 0,
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.query.orderedBefore = "")),
                            icon: _ctx.close
                          }, null, 8, ["icon"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Promised after")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_chip, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "aria-label": "promised-after",
                        modelValue: _ctx.query.promisedAfter,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.query.promisedAfter) = $event)),
                        onIonChange: _cache[11] || (_cache[11] = ($event: any) => {_ctx.query.promisedAfter = $event.target.value; _ctx.updateQuery()}),
                        type: "date"
                      }, null, 8, ["modelValue"]),
                      (_ctx.query.promisedAfter)
                        ? (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 0,
                            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.query.promisedAfter = "")),
                            icon: _ctx.close
                          }, null, 8, ["icon"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Promised before")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_chip, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "aria-label": "promised-before",
                        modelValue: _ctx.query.promisedBefore,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.query.promisedBefore) = $event)),
                        onIonChange: _cache[14] || (_cache[14] = ($event: any) => {_ctx.query.promisedBefore = $event.target.value; _ctx.updateQuery()}),
                        type: "date"
                      }, null, 8, ["modelValue"]),
                      (_ctx.query.promisedBefore)
                        ? (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 0,
                            onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.query.promisedBefore = "")),
                            icon: _ctx.close
                          }, null, 8, ["icon"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Loyalty status")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_chip, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.ribbon }, null, 8, ["icon"]),
                      _createVNode(_component_ion_select, {
                        "aria-label": "loyalty-status",
                        placeholder: _ctx.$t('select'),
                        modelValue: _ctx.query.cusotmerLoyalty,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.query.cusotmerLoyalty) = $event)),
                        onIonChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.updateQuery())),
                        interface: "popover",
                        "interface-options": "{showBackdrop:false}"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cusotmerLoyaltyOptions, (key, value) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: key,
                              value: value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(key), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["placeholder", "modelValue"]),
                      (_ctx.query.cusotmerLoyalty)
                        ? (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 0,
                            onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.query.cusotmerLoyalty = "")),
                            icon: _ctx.close
                          }, null, 8, ["icon"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toggle, {
                    onIonChange: _cache[19] || (_cache[19] = ($event: any) => {_ctx.query.hasPromisedDate = !_ctx.query.hasPromisedDate; _ctx.updateQuery()}),
                    checked: !_ctx.query.hasPromisedDate
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Only orders without promise date")), 1)
                    ]),
                    _: 1
                  }, 8, ["checked"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ion_badge, { color: "success" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.preorderCount) + " " + _toDisplayString(_ctx.$t("items preordered")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_badge, { color: "secondary" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.ordersTotal) + " " + _toDisplayString(_ctx.$t("orders")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          (_ctx.orders.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_ctx.query.hasUpdated)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("No results found")), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("Enter an order ID, product name, style name, SKU, customer name, UPCA or external ID")), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "order",
                    key: order.orderId
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h1", null, _toDisplayString(order.doclist.docs[0].orderName ? order.doclist.docs[0].orderName : order.doclist.docs[0].orderId), 1),
                                _createElementVNode("p", null, _toDisplayString(order.doclist.docs[0].customerPartyName), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        (_ctx.$filters.getCustomerLoyalty(order.doclist.docs[0].orderNotes, _ctx.cusotmerLoyaltyOptions))
                          ? (_openBlock(), _createBlock(_component_ion_chip, {
                              key: 0,
                              outline: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.ribbon }, null, 8, ["icon"]),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$filters.getCustomerLoyalty(order.doclist.docs[0].orderNotes, _ctx.cusotmerLoyaltyOptions)), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_ion_note, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("Order placed on")) + " " + _toDisplayString(_ctx.$filters.formatUtcDate(order.doclist.docs[0].orderDate, 'YYYY-MM-DDTHH:mm:ssZ')), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.doclist.docs, (item) => {
                        return (_openBlock(), _createBlock(_component_ion_card, {
                          key: item.orderItemSeqId
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_DxpShopifyImg, {
                                      src: _ctx.getProduct(item.productId).mainImageUrl,
                                      size: "small"
                                    }, null, 8, ["src"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("h2", null, _toDisplayString(item.parentProductName ? item.parentProductName :item.productName), 1),
                                    (_ctx.$filters.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/COLOR/'))
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.$t("Color")) + " : " + _toDisplayString(_ctx.$filters.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/COLOR/')), 1))
                                      : _createCommentVNode("", true),
                                    (_ctx.$filters.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/SIZE/'))
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.$t("Size")) + " : " + _toDisplayString(_ctx.$filters.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/SIZE/')), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t ("Available to promise")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.getProductStock(item.productId)), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_item, { lines: "full" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("Promised date")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("p", _hoisted_16, _toDisplayString(item.promisedDatetime ? _ctx.$filters.formatUtcDate(item.promisedDatetime, "yyyy-MM-dd'T'HH:mm:ss'Z'") : '-'), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_item, {
                              button: "",
                              onClick: ($event: any) => (item.isChecked = !item.isChecked),
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_checkbox, {
                                  modelValue: item.isChecked,
                                  onIonChange: ($event: any) => (_ctx.selectItem($event, item)),
                                  "label-placement": "end",
                                  justify: "start"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("Select item")), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["modelValue", "onIonChange"]),
                                _createVNode(_component_ion_button, {
                                  fill: "clear",
                                  color: "medium",
                                  onClick: _withModifiers(($event: any) => (_ctx.openPopover($event, item)), ["stop"])
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      slot: "icon-only",
                                      icon: _ctx.ellipsisVertical
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ])
                  ]))
                }), 128)),
                _withDirectives(_createVNode(_component_ion_infinite_scroll, {
                  onIonInfinite: _cache[20] || (_cache[20] = ($event: any) => (_ctx.loadMoreOrders($event))),
                  threshold: "100px",
                  id: "infinite-scroll",
                  ref: "infiniteScrollRef"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_infinite_scroll_content, {
                      "loading-spinner": "crescent",
                      "loading-text": _ctx.$t('Loading')
                    }, null, 8, ["loading-text"])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.isScrolleable]
                ])
              ]))
        ]),
        _: 1
      }, 512),
      _createVNode(_component_ion_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    disabled: !_ctx.selectedItemsCount,
                    onClick: _ctx.releaseAlert,
                    fill: "outline",
                    color: "primary",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.send
                      }, null, 8, ["icon"]),
                      _createTextVNode(_toDisplayString(_ctx.$t("Release")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]),
                  _createVNode(_component_ion_button, {
                    disabled: !_ctx.selectedItemsCount,
                    onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.openWarehouseList(_ctx.selectedItems))),
                    fill: "outline",
                    color: "dark",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.business
                      }, null, 8, ["icon"]),
                      _createTextVNode(_toDisplayString(_ctx.$t("Release to a warehouse")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_ion_button, {
                    disabled: !_ctx.selectedItemsCount,
                    onClick:  _ctx.editPromiseDate,
                    fill: "outline",
                    color: "dark",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.calendar
                      }, null, 8, ["icon"]),
                      _createTextVNode(_toDisplayString(_ctx.$t("Edit promise date")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]),
                  _createVNode(_component_ion_button, {
                    disabled: !_ctx.selectedItemsCount,
                    onClick: _ctx.cancelAlert,
                    fill: "outline",
                    color: "danger",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.closeCircle
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("Cancel")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}